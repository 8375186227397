import React from "react";
import devnet from "../assets/devnet.png";
import vidona from "../assets/vidona.png";
import client from "../assets/client.png";
import client2 from "../assets/client2.png";
import xml from "../assets/xml.png";
import imgcropper from "../assets/imgcropper.png";
import insideout from "../assets/insideout.png";
import hegra from "../assets/hegra.png";

import { Project } from "./Project";

export const Projects = (props) => {
  return (
    <section id="projects">
      <div className="projects-container">
        <div className="heading">
          <h3 className="title">My Projects</h3>
          <p className="separator" />
          <p className="subtitle">
            Here's a list of <u>most</u> of the projects I've been working on
            lately. I built all the projects to get the Full-Stack Developer
            experience.
          </p>
        </div>
        <div className="projects-wrapper">
          <Project
            title="Insideout"
            img={insideout}
            tech="js css react node"
            link="https://insideout-solutions.net/"
            repo="https://insideout-solutions.net/"
          >
            <small>
              Built using Node, Express, MongoDB, CSS, Vue.js an Vuetify.
            </small>
            <p>
              This is a full-stack website that I built to represent a
              web-solutions company.
            </p>
          </Project>
          <Project
            title="DevNet"
            img={devnet}
            tech="js css react node"
            link="https://dev-net-56285.herokuapp.com/"
            repo="https://github.com/HamdySarhan/DevNet"
          >
            <small>
              Built using Node, Express, MongoDB, CSS, React.js and React
              Router.
            </small>
            <p>
              This is a full-stack website that I built which lets the users
              creat their own portfolio and comunicate with other users via
              posts and comments.
            </p>
          </Project>
          <Project
            title="Hegra.tech"
            img={hegra}
            tech="js css react node"
            link="https://hegra.tech/"
            repo="https://hegra.tech/"
          >
            <small>
              Built using Node, Express, MongoDB, CSS, React.js and React
              Router.
            </small>
            <p>
              This is a full-stack website that I built to helb students from my
              country find the program of study that they want in a german
              university providing them with information about each university,
              city and program in germany.
            </p>
          </Project>
          <Project
            title="Vidona"
            img={vidona}
            tech="js node css"
            link="https://vidona.net/"
            repo="https://vidona.net/"
          >
            <small>
              Built using Node, Express, MongoDB, JS + React + Axios, CSS.
            </small>
            <p>
              Another full-stack website that uses AI to detect keywords in
              videos and suggest free stock photos and videos + animation. It
              also detects silence parts in videos and suggest trimming.
            </p>
          </Project>
          <Project
            title="Client portfolio"
            img={client}
            tech="js node css"
            link="https://abdallahsarhan-law.com/"
            repo="https://github.com/HamdySarhan/client-portfolio"
          >
            <small>Built using React, MongoDB, JS + CSS + Bootstrap.</small>
            <p>A full-stack portfolio website for a legal consulting office.</p>
          </Project>
          <Project
            title="Client portfolio"
            img={client2}
            tech="js node css"
            link="https://66e33997be8b44164bf97e32--gorgeous-conkies-e5c167.netlify.app/"
            repo="https://66e33997be8b44164bf97e32--gorgeous-conkies-e5c167.netlify.app/"
          >
            <small>Built using Vue, Vuetify, Vite, JS + CSS.</small>
            <p>A full-stack portfolio website for a legal consulting office.</p>
          </Project>
          <Project
            title="Automated Image Cropper."
            img={imgcropper}
            tech="c++ qt opencv"
            link="https://github.com/HamdySarhan/ImgCropper/tree/main/ImgCropper"
            repo="https://github.com/HamdySarhan/ImgCropper/tree/main/ImgCropper"
          >
            <small>Built using C++, Qt,and OpenCV.</small>
            <p>
              An automated image cropper that allows user to crop a huge amount
              of image with the same ratios in seconds to prepare for AI model
              training.
            </p>
          </Project>
          <Project
            title="XML Modifire."
            img={xml}
            tech="c++ qt"
            link="https://github.com/HamdySarhan/XMLModiC-Version"
            repo="https://github.com/HamdySarhan/XMLModiC-Version"
          >
            <small>Built using C++, Qt and CMake.</small>
            <p>
              Automated XML modifire that allows direct access and modifiction
              to xml files using keys to spare time.
            </p>
          </Project>
        </div>
      </div>
    </section>
  );
};
